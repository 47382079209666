import Typography from "typography";
// import OceanBeach from "typography-theme-ocean-beach";
import Meetter from "./meetter-typography-theme";

const typography = new Typography(Meetter);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
