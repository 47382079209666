import React from "react";

export const RequestAccessBtn = ({
  children,
  text = "Get Started",
  ...props
}) => (
  <a
    href="/getstarted/"
    className="request-a-demo mdc-button mdc-button--unelevated"
    {...props}
  >
    {children && children.length > 0 ? children : "Get Started"}
  </a>
);
