import React from "react";
import { Link } from "gatsby";
import { RequestAccessBtn } from "./request-access-btn";
import { ProductNameContext } from "../context";

const Tab = ({ to, children, className }) => (
  <Link
    to={to}
    className={"top-menu-link " + className}
    activeClassName="active"
  >
    {children}
    <div className={"border"}></div>
  </Link>
);

export const Header = () => {
  const productName = React.useContext(ProductNameContext);

  return (
    <header className="header">
      <div className="meetter-logo">
        <Link to={"/"}>
          <img
            className="inline-logo no-mg-bottom"
            src={require("../../../images/128x128.png")}
            alt={`${productName} logo`}
          />{" "}
          <span className="desktop-only">{productName}</span>
        </Link>
      </div>

      <span className="horizontal-spacer"></span>

      <nav className={"tabs"}>
        <Tab to="/">Overview</Tab>
        <Tab to="/pricing">Pricing</Tab>
        {/*hiding blog from header because no new content*/}
        {/*<Tab className="hide-on-xs" to="/blog">Blog</Tab>*/}
        <Tab to="/changelog">Changelog</Tab>
        <Tab className="hide-on-xs" to="/whitepaper">
          Whitepaper
        </Tab>
      </nav>

      <span className="horizontal-spacer"></span>

      {/*TODO: @gene, this seem to be unfinished and commited by mistake in copy pr?*/}
      {/*<div style={{ textAlign: "center", marginRight: "8px" }}>*/}
      {/*  <a href="/signin/" className="signin mdc-button mdc-button--text">*/}
      {/*    Sign in*/}
      {/*  </a>*/}
      {/*</div>*/}

      <RequestAccessBtn />
    </header>
  );
};
