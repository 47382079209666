import React from "react";

import { rhythm } from "../utils/typography";
import { Header } from "./header";
import { Footer } from "./footer";
import SentryErrorBoundary from "./sentry";

class Layout extends React.Component {
  render() {
    const { children, withMaxWidth = true } = this.props;

    return (
      <SentryErrorBoundary>
        <div className="mdc-typography">
          <Header />
          <main
            style={
              withMaxWidth
                ? {
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    maxWidth: rhythm(24),
                    minHeight: `calc(100vh - 300px)`,
                    padding: `calc(${rhythm(1.5)} + 58px) ${rhythm(3 / 4)}`,
                  }
                : {}
            }
          >
            {children}
          </main>
          <Footer />
        </div>
      </SentryErrorBoundary>
    );
  }
}

export default Layout;
