import React from "react";

export function captureException(e) {
  if (!window.Sentry) return;
  window.Sentry.captureException(e);
}

class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (!window.Sentry) return;
    window.Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = window.Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    // TODO:
    // if (this.state.hasError) {
    //   //render fallback UI
    //   return (
    //     <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
    //   );
    // }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryErrorBoundary;
